
import { SuperVue } from '@/mixins/SuperVue'
import { Options, Vue } from 'vue-class-component'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import { httpRequest } from '@/plugins'

@Options({
  components: {
    PagePlugin
  }
})
export default class Register extends SuperVue {
  search = {
    user_name: '',
    api_name: ''
  }
  tb = { data: [], page: 1, pageSize: 10, total: 0 }
  created() {
    this.getTbData(0, 10)
  }
  detail() {}
  getTbData(offset, length) {
    const data = {
      offset,
      length,
      ...this.search
    }
    httpRequest.post('/api/v1/logs/getLogList', data).then((res: any) => {
      if (res.code === '200') {
        this.tb.data = res.data.data
        this.tb.total = res.data.total
      }
    })
  }
  pageChange(e) {
    this.tb.page = e.currentPage
    this.tb.pageSize = e.pageSize
  }
}
